
import { Component, Vue } from "vue-property-decorator";
import OrderApi, { CamwaterOrderRequest } from "@/services/api/order";
import { AxiosError, AxiosResponse } from "axios";
import ErrorService, { ValidationErrors } from "@/services/errors";
import Order from "@/models/order";
import {
    mdiAlert,
    mdiCallMade,
    mdiCheckCircle,
    mdiPlus,
    mdiWaterPump,
} from "@mdi/js";
import { Action, Getter } from "vuex-class";
import { User } from "@/models/user";
import UserApi from "@/services/api/user";
import firebase, {
    addAnalyticsEvent,
    ensureAuthenticated,
} from "@/plugins/firebase";
import { captureSentryException, captureSentryMessage } from "@/plugins/sentry";
import StatusCodes from "http-status-codes";
import CompositeAlert from "@/components/CompositeAlert.vue";
import UserProfile from "@/models/user-profile";
import BillAmount from "@/models/bill-amount";
import Utilities, { GetUtilitiesAmountRequest } from "@/services/api/utilities";
import { ApiResponse } from "@/services/api/axios";

@Component({
    components: {
        CompositeAlert,
    },
})
export default class CamwaterIndex extends Vue {
    formEmail: string = "";
    formPaymentMethod: string = "mtn-mobile-money";
    formPhoneNumber: string = "";
    waterIcon: string = mdiWaterPump;
    formInputErrors: ValidationErrors = new ValidationErrors();
    isSubmitting: boolean = false;
    isValidatingBillNumber = false;
    formAmount: number | null = null;
    formBillNumber = "";
    payIcon: string = mdiCallMade;
    tickIcon: string = mdiCheckCircle;
    plusIcon: string = mdiPlus;
    alertIcon: string = mdiAlert;
    billAmount: BillAmount | null = null;
    billNumberIsValid = false;

    @Getter("user") user!: User | null;
    @Getter("userIsAnonymous") userIsAnonymous!: boolean;
    @Getter("authToken") authToken!: string;
    @Getter("userIsAdmin") userIsAdmin!: boolean;
    @Getter("referralCode") referralCode!: string;
    @Action("setUser") setUser!: (user: User | null) => void;
    @Action("setAuthToken") setAuthToken!: (authToken: string | null) => void;

    get formPhoneNumberPlaceholder(): string {
        if (!this.formPaymentMethod) {
            return "Phone number e.g 6xxxxxxxx";
        }
        return `${this.paymentMethodString} number e.g 6xxxxxxxx.`;
    }

    get canCalculateFees(): boolean {
        return this.billAmount !== null;
    }

    get paymentMethodString(): string {
        if (this.formPaymentMethod == "mtn-mobile-money") {
            return "MTN Mobile Money";
        }
        return "Orange Money";
    }

    payCamwaterBill() {
        addAnalyticsEvent("pay_camwater_clicked");
        this.isSubmitting = true;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleError = (error: any) => {
            this.isSubmitting = false;
            this.handleError(error);
        };

        this.$recaptcha("buy_airtime")
            .then((token: string) => {
                const payload: CamwaterOrderRequest = {
                    captcha: token,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    payment_method: this.formPaymentMethod,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    phone_number: this.formPhoneNumber,
                    email: this.formEmail,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    bill_number: this.formBillNumber,
                };
                this.ensureUserExists()
                    .then(() => {
                        this.createOrder(payload);
                    })
                    .catch(handleError);
            })
            .catch(handleError);
    }

    setProfileFromAuthUser() {
        this.formEmail = this.user?.email || "";
    }

    getAmount() {
        this.isValidatingBillNumber = true;
        this.billNumberIsValid = false;
        this.billAmount = null;

        this.$recaptcha("validate_bill_number")
            .then((token: string) => {
                const params: GetUtilitiesAmountRequest = {
                    captcha: token,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    bill_number: this.formBillNumber,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    payment_method: this.formPaymentMethod,
                    category: "camwater",
                };
                Utilities.getAmount(params)
                    .then((response: AxiosResponse) => {
                        this.formInputErrors.clear("bill_number");
                        this.billAmount = new BillAmount(response.data.data);
                        this.formAmount = this.billAmount.totalAmount;
                        this.billNumberIsValid = true;
                    })
                    .catch(this.handleAxiosError)
                    .finally(() => {
                        this.isValidatingBillNumber = false;
                    });
            })
            .catch((error) => {
                this.isValidatingBillNumber = false;
                this.handleError(error);
            });
    }

    loadProfile() {
        if (!firebase.auth().currentUser?.uid) {
            captureSentryException(
                "cannot load profile for an unauthenticated user"
            );
            return;
        }

        UserApi.getProfile(firebase.auth().currentUser?.uid as string)
            .then((response: AxiosResponse) => {
                const userProfile = new UserProfile(response.data.data);
                this.formPhoneNumber = userProfile.paymentPhoneNumber ?? "";
                this.formEmail = userProfile.email ?? this.formEmail;
                this.formBillNumber =
                    userProfile.camwater?.contractNumber ?? this.formBillNumber;
                if (this.formBillNumber) {
                    this.getAmount();
                }
            })
            .catch((error: AxiosError<ApiResponse>) => {
                if (error.response?.status === StatusCodes.NOT_FOUND) {
                    this.setProfileFromAuthUser();
                    return;
                }
                this.handleAxiosError(error);
            });
    }

    mounted() {
        if (this.user || this.userIsAnonymous) {
            ensureAuthenticated()
                .then(() => {
                    this.loadProfile();
                })
                .catch(this.handleError);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError(error: any) {
        if (error instanceof Error && error.message) {
            captureSentryException(error);
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.ERROR,
                error.message
            );
            return;
        }
        captureSentryMessage(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            "We could not carry out your request. It may be because your internet connection is not so good. Please try again"
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ensureUserExists(): Promise<any> {
        if (this.authToken) {
            return new Promise<boolean>((resolve) => resolve(true));
        }
        return firebase
            .auth()
            .signInAnonymously()
            .then(async (response) => {
                this.setUser(response.user);
                this.setAuthToken((await response.user?.getIdToken()) || null);
                addAnalyticsEvent("anonymous_user_created_before_order");
            });
    }

    createOrder(payload: CamwaterOrderRequest) {
        OrderApi.createCamwater(payload)
            .then((response: AxiosResponse) => {
                const order = new Order(response.data.data);
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    "Your order has been created successfully"
                );

                addAnalyticsEvent("begin_checkout", {
                    currency: "XAF",
                    value: order.paymentAmount,
                    items: [
                        {
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            item_id: order.itemId,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            item_name: order.itemName,
                            affiliation: "CAMWATER",
                            currency: "XAF",
                        },
                    ],
                });

                this.$router.push({
                    name: this.$constants.ROUTE_NAMES.ORDERS_SHOW,
                    params: {
                        orderId: order.orderId,
                    },
                });
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.formInputErrors = ErrorService.getValidationErrors(error);
        this.handleError(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }
}
